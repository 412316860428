/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import Header from '../Header';
import Footer from '../Footer';

import '../../styles/main.scss';

const Layout = ({
  children, location, cta, short, grey,
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header location={location} siteTitle={data.site.siteMetadata.title} cta={cta} />
        <main className={`${grey && 'main'}`}>{children}</main>
        <Footer location={location} short={short} />
      </>
    )
    }
  />
);

Layout.defaultProps = {
  cta: true,
  short: false,
  grey: false,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
  cta: PropTypes.bool,
  short: PropTypes.bool,
  grey: PropTypes.bool,
};

export default Layout;
