import React from 'react';
import PropTypes from 'prop-types';
import Copyright from '@ropto/ui-copyright';
import { Link } from 'gatsby';

import './styles.scss';

const Footer = ({ short, location }) => (
  <>
    {short ? (
      <footer className="footer-short" id="footer">
        <div className="container">
          <div className="footer-short__copyright">
            <Copyright productName="My Money Back." />
          </div>
        </div>
      </footer>
    ) : (
      <footer className="footer" id="footer">
        <div>
          <div className="footer__copyright">
            <strong>
              <Copyright productName="My Money Back. All rights reserved." />
            </strong>
          </div>
          <div className="footer__copyright">
            My Money Back is a trading style of Ropto Ltd. Ropto Ltd is registered in England.
            Company no: 08915874. Ropto Ltd is authorised and regulated by the Financial Conduct
            Authority (FRN 945571), in respect of regulated claims management activity.
            Registered address: Hamilton House, Church Street, Altrincham, WA14 4DR.
          </div>
          <ul className="footer__links">
            <li>
              <Link className="footer__link" to={`/legal-notice/${location.search}`}>Legal Notice &gt;</Link>
            </li>
            <li>
              <Link className="footer__link" to={`/privacy-policy/${location.search}`}>Privacy Policy &gt;</Link>
            </li>
            <li>
              <Link className="footer__link" to={`/complaints-policy/${location.search}`}>Complaints Policy &gt;</Link>
            </li>
          </ul>
        </div>
      </footer>
    )}
  </>

);

Footer.defaultProps = {
  short: true,
};

Footer.propTypes = {
  short: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Footer;
