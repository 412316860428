import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Menu from './Menu';
import './styles.scss';
import logo from '../../images/my-money-back-logo.svg';

const Header = ({ siteTitle, cta, location }) => (
  <header className="header">
    <div className={`header__container ${!cta && 'header__container--center'}`}>
      <div className="header__logo">
        <Link to={`/${location.search}`}>
          <img
            src={logo}
            alt={siteTitle}
          />
        </Link>
      </div>
      {cta && <Menu location={location} />}
    </div>
  </header>
);

Header.defaultProps = {
  cta: true,
};

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  cta: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Header;
